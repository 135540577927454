export const categories = {
  algemeen: 1,
  aardbevingsschade: 2,
  subsidies: 3,
  verduurzaming: 4,
  gegevens: 5
}

export const questionTypes = {
  checklist: 1,
  input: 2,
  form: 3
}


// questions (this is not an enum but whatever)
export const initialQuestions = [
  {
    category: categories.algemeen,
    question: "Bent u eigenaar van de woning?",
    type: questionTypes.checklist,
    fields: [
      { text: 'Ja' },
      {
        text: 'Nee',
        FollowUpConclusion: {
          Conclusion: "Wij kunnen u helaas niet verder helpen.",
          HideNextStepButton: true
        }
      },
    ]
  },
  {
    category: categories.aardbevingsschade,
    question: "Bevindt uw woning zich in het aardbevingsschade gebied?",
    type: questionTypes.checklist,
    fields: [
      { text: 'Ja' },
      { text: 'Nee', skipTo: 4 },
      {
        text: 'Weet ik niet',
        skipTo: 4,
        FollowUpConclusion: {
          Conclusion: "Wij kijken graag voor u of uw woning zich in een aardbevingsschade gebied bevindt.",
        }
      }
    ]
  },
  {
    category: categories.aardbevingsschade,
    question: "Heeft u eeder aardbevingsschade gemeld aan de woning?",
    type: questionTypes.checklist,
    fields: [
      { text: 'Ja' },
      { 
        text: 'Nee',
        skipTo: 4,
        FollowUpConclusion: {
          Conclusion: "Bijna iedere woning in het bevingsgebied heeft recht op een vergoeding voor de aardbevingsschade. Wij komen graag vrijblijvend met u in contact om de mogelijkheden voor uw woning te bespreken.",
        }
      },
    ]
  },
  {
    category: categories.aardbevingsschade,
    question: "Voor welke regeling heeft u destijds gekozen?",
    type: questionTypes.checklist,
    fields: [
      {
        text: 'Maatwerkprocedure',
        FollowUpConclusion: {
          Conclusion: "Op het gebied van aardbevingsschade zijn er wellicht nog mogelijkheden voor u. Denk hierbij aan vergoedingen voor nieuw ontstane schade(s), verergering van schades of schades die tijdens een inspectie over het hoofd zijn gezien. Aan de hand van een vrijblijvende schadecheck bekijken wij graag de mogelijkheden voor een nieuwe schadevergoeding met u.",
        }
      },
      {
        text: 'Vaste vergoeding',
        FollowUpConclusion: {
          Conclusion: "Op het gebied van aardbevingsschade zijn er wellicht nog mogelijkheden voor u. Zo werkt Het Instituut Mijnbouwschade Groningen aan een aanvulling op de vaste vergoeding. We bekijken graag wat dit voor u kan gaan betekenen.",
        }
      }
    ]
  },
  {
    category: categories.subsidies,
    question: "In welk postcodegebied bevindt de woning zich? (alleen eerste 4 cijfers)",
    type: questionTypes.input,
    fields: [
      { text: 'Postcode', checkPostalCode: true },
    ]
  },
  {
    category: categories.subsidies,
    question: "Heeft u erkende aardbevingsschade van meer dan €1000?",
    type: questionTypes.checklist,
    fields: [
      { text: 'Ja', increaseSubsidie: true },
      { text: 'Nee' }
    ]
  },
  {
    category: categories.subsidies,
    question: "Is uw woning als karakteristiek pand, gemeentelijk monument of rijksmonument aangemerkt?",
    type: questionTypes.checklist,
    fields: [
      { text: 'Ja', increaseSubsidie: true },
      { text: 'Nee' },
      { 
        text: 'Weet ik niet',
        increaseSubsidie: true,
        FollowUpConclusion: {
          Conclusion: "Wij zoeken dit graag voor u uit.",
        }
      },
    ]
  },
  {
    category: categories.subsidies,
    question: "Heeft u de afgelopen twee jaar isolatie, glas, een warmtepomp of een zonneboiler laten plaatsen?",
    type: questionTypes.checklist,
    fields: [
      { text: 'Ja', increaseSubsidie: true, showSubsidy: true },
      { text: 'Nee', showSubsidy: true }
    ]
  },
  {
    category: categories.verduurzaming,
    question: "Bent u opzoek naar advies/hulp bij het verduurzamen van de woning?",
    type: questionTypes.checklist,
    fields: [
      { text: 'Ja' },
      { text: 'Nee' }
    ]
  },
  {
    category: categories.verduurzaming,
    question: "Bent u opzoek naar een betrouwbare partner bij het uitvoeren van de verduurzaming?",
    type: questionTypes.checklist,
    fields: [
      { text: 'Ja' },
      { text: 'Nee' }
    ]
  },
  {
    category: categories.gegevens,
    type: questionTypes.form,
    fields: [],
    question: "Wilt u meer weten over de mogelijkheden voor uw woning? Laat uw gegevens achter, dan nemen wij vrijblijvend contact met u op."
  },
];

export const postcodes10000Regeling = [9474, 9479, 9512, 9601, 9602, 9603, 9605, 9606, 9607, 9608, 9609, 9611, 9613, 9614, 9615, 9616, 9617,
  9618, 9619, 9621, 9622, 9623, 9624, 9625, 9626, 9627, 9628, 9629, 9631, 9632, 9633, 9635, 9636, 9641, 9642, 9644, 9645, 9646, 9648,
  9649, 9651, 9654, 9655, 9656, 9657, 9659, 9672, 9675, 9677, 9678, 9679, 9681, 9682, 9684, 9685, 9723, 9731, 9732, 9733, 9734, 9735,
  9736, 9737, 9738, 9746, 9747, 9771, 9773, 9774, 9781, 9784, 9785, 9791, 9792, 9793, 9794, 9795, 9796, 9797, 9798, 9833, 9881, 9882,
  9883, 9884, 9885, 9886, 9891, 9892, 9893, 9901, 9902, 9903, 9904, 9905, 9906, 9907, 9908, 9909, 9911, 9912, 9913, 9914, 9915, 9917,
  9918, 9919, 9921, 9922, 9923, 9924, 9925, 9931, 9932, 9933, 9934, 9936, 9937, 9939, 9942, 9943, 9944, 9945, 9946, 9947, 9948, 9949,
  9951, 9953, 9954, 9955, 9956, 9957, 9959, 9961, 9962, 9963, 9964, 9965, 9966, 9967, 9968, 9969, 9971, 9972, 9973, 9974, 9975, 9976,
  9977, 9978, 9979, 9981, 9982, 9983, 9984, 9985, 9986, 9987, 9988, 9989, 9991, 9992, 9993, 9994, 9995, 9996, 9997, 9998, 9999];

export const postcodesWaardedalingsregeling =   [9655, 9656, 9657, 9659, 9901, 9902, 9903, 9904, 9905, 9906, 9907, 9908,
  9909, 9911, 9912, 9913, 9914, 9915, 9917, 9918, 9919, 9921, 9922, 9923, 9924, 9931, 9932, 9933, 9934,
  9936, 9937, 9945, 9946, 9947, 9948, 9949, 9987, 9991, 9992, 9993, 9994, 9613, 9614, 9622, 9723, 9731,
  9732, 9733, 9734, 9735, 9736, 9737, 9738, 9746, 9747, 9791, 9792, 9793, 9794, 9795, 9796, 9797, 9798,
  9771, 9773, 9774, 9781, 9784, 9785, 9925, 9951, 9953, 9954, 9955, 9956, 9957, 9959, 9961, 9962, 9963,
  9964, 9965, 9966, 9967, 9968, 9969, 9971, 9973, 9975, 9977, 9978, 9979, 9981, 9982, 9983, 9984, 9985,
  9986, 9988, 9989, 9995, 9996, 9997, 9998, 9999, 9601, 9602, 9603, 9605, 9606, 9607, 9608, 9609, 9611,
  9615, 9616, 9617, 9618, 9619, 9621, 9623, 9624, 9625, 9626, 9627, 9628, 9629, 9632, 9633, 9635, 9636,
  9649, 9651, 9939, 9679, 9681, 9942, 9943, 9944, 9474, 9644, 9833, 9884, 9886, 9891, 9892, 9893];